<script setup lang="ts">
import { getPronoun } from '~/src/buildPronoun.ts';
import type { Pronoun, Source } from '~/src/classes.ts';
import { pronouns } from '~/src/data.ts';

const props = defineProps<{
    pronoun?: Pronoun;
    sources: Record<string, Source[] | undefined>;
}>();

const { $translator: translator } = useNuxtApp();

const glue = ` ${translator.translate('pronouns.or')} `;

const visibleSources = computed((): Record<string, Source[]> => {
    return Object.fromEntries(
        Object.entries(props.sources).filter(([_, sources]) => sources),
    ) as Record<string, Source[]>;
});
</script>

<template>
    <div v-if="Object.keys(visibleSources).length">
        <h2 class="h4">
            <nuxt-link :to="{ name: 'sources' }">
                <Icon v="books" />
                <T>sources.headerLong</T><T>quotation.colon</T>
            </nuxt-link>
        </h2>

        <section v-for="(optionSources, option) in visibleSources" :key="option">
            <h3 class="h5">
                <nuxt-link :to="`/${option}`">
                    <span v-if="option.includes('&')">
                        <T>pronouns.alt.header</T>
                        <small>({{ option.replace(/&/g, glue) }})</small>
                    </span>
                    <span v-else-if="option && getPronoun(pronouns, option)">
                        {{ getPronoun(pronouns, option)?.description }}
                        <small>({{ getPronoun(pronouns, option)?.name(glue) }})</small>
                    </span>
                </nuxt-link>
            </h3>

            <SourceList :pronoun="pronoun" :sources="optionSources" />
        </section>
    </div>
</template>
